import axios from 'axios'
// import { Notification } from 'element-ui'
import store from '@/store'
import { getToken } from '@/lib/auth'
import errorCode from '@/lib/errorCode'
import { signEncrypt, getQuery } from '@/lib/encryptSign.js';
import Vue from 'vue'
import { Dialog, Toast } from 'vant'
Vue.use(Dialog);

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  // get请求映射params参数
  if (config.method === 'get') {
    let url = config.url + '?'
    if (sessionStorage.getItem('userId')) {
      url = config.url + '?' + 'userId=' + sessionStorage.getItem('userId') + '&';
    }
    if (config.params) {
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof (value) !== "undefined") {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                let params = propName + '[' + key + ']';
                let subPart = encodeURIComponent(params) + '=';
                url += subPart + encodeURIComponent(value[key]) + '&';
              }
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (config.signEncrypt) { // 使用加密
    config.headers['appId'] = process.env.VUE_APP_APPID
    config.headers['sign'] = config.method === 'get' ? signEncrypt(getQuery(config.url)) : signEncrypt(config.data)
    if (getQuery(config.url).pageNum) {//分页参数不参与加密
      let nurl = getQuery(config.url)
      delete nurl.pageNum
      delete nurl.pageSize
      config.headers['sign'] = config.method === 'get' ? signEncrypt(nurl) : signEncrypt(config.data)
    }
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  if (code === 401) {
    Dialog.confirm({
      title: '提示',
      message: '登录状态已过期'
    }).then(() => {
      store.dispatch('LogOut').then(() => {
        location.href = '/index';
      })
    }).catch(() => { })
    // MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
    //     confirmButtonText: '重新登录',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }
    // ).then(() => {
    //   store.dispatch('LogOut').then(() => {
    //     location.href = '/index';
    //   })
    // }).catch(() => {});
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
  } else if (code === 500) {
    // Message({
    //   message: msg,
    //   type: 'error'
    // })
    Toast.fail(msg)
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    // Notification.error({
    //   title: msg
    // })
    Toast.fail(msg);
    return Promise.reject('error')
  } else {
    return res.data
  }
},
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Toast.fail(message);
    // Message({
    //   message: message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
